import React, { useState, useCallback } from 'react';
import { Card, CardTitle, Button } from 'releox-react';
import merge from 'deepmerge';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { ReduxDispatch } from '../interfaces/ReduxDispatch';
import { createQuestionAction, questionInitValues } from '../store/actions/question.actions';
import { Question } from '../interfaces/Question';
import QuestionForm, { questionValidationSchema } from '../forms/QuestionForm';

export default () => {
  const dispatch = useDispatch<ReduxDispatch>();
  const [categories, setCategories] = useState<string[]>([]);
  const [reRender, setReRender] = useState(0);

  const onSubmit = useCallback(
    (body: Question) => {
      dispatch(createQuestionAction(body));
      setCategories(body.categories);
      setReRender(reRender + 1);
    },
    [setCategories, dispatch, setReRender, reRender]
  );

  return (
    <div className="row">
      <div className="col-lg-6 offset-lg-3">
        <Card key={reRender}>
          <CardTitle>Luo kysymys</CardTitle>
          <Formik
            initialValues={merge<Question>(questionInitValues, { categories })}
            enableReinitialize
            validationSchema={questionValidationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <QuestionForm />
              <Button id="submit" type="submit">
                Tallenna
              </Button>
            </Form>
          </Formik>
        </Card>
      </div>
    </div>
  );
};
