import { ReduxStore } from '../reducer';

export default class QuizSelector {
  public static selectNoMoreQuestions(state: ReduxStore) {
    const { questions, currentQuestion } = state.quizEntry;
    if (questions.length === 0 && currentQuestion.id === '') {
      return true;
    }
    return false;
  }
}
