import { push } from 'react-router-redux';
import { actionCreator } from 'redux-rest-helper-for-loopback';
import URL from '../../URL';
import TableOfContentsEffect from './TableOfContentsEffect';

export default class TableOfContentsAction {
  static ENTRY = 'TABLE_OF_CONTENT';

  static actionCreator = actionCreator(
    TableOfContentsAction.ENTRY,
    TableOfContentsEffect.restRequests,
    push
  );

  static update = TableOfContentsAction.actionCreator.getUpdateAction(URL.TABLE_OF_CONTENT.INDEX);

  static fetch = TableOfContentsAction.actionCreator.getFetchAction();

  static list = TableOfContentsAction.actionCreator.getListAndCountAction();

  static clean = TableOfContentsAction.actionCreator.getCleanAction();
}
