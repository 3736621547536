import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, DetailField, Loading } from 'releox-react';
import DriveFile from '../interfaces/DriveFile';
import { ReduxDispatch } from '../interfaces/ReduxDispatch';
import { answerAction, AnswerType } from '../store/actions/quiz.actions';
import { ReduxStore } from '../store/reducer';
import URL from '../URL';

const getFileUrl = (file: DriveFile) =>
  `${URL.ContainerAPI}/${file.bucket}/download/${file.fileName}`;

const QuizQuestion = () => {
  const dispatch = useDispatch<ReduxDispatch>();
  const question = useSelector((state: ReduxStore) => state.quizEntry.currentQuestion);
  const categories = useSelector((state: ReduxStore) => state.categoryEntry.list.data);
  const onAnswer = useCallback(
    (isCorrect: AnswerType) => () => dispatch(answerAction(isCorrect, question)),
    [question, dispatch]
  );

  const buttons = [
    {
      text: 'Vastasin oikein',
      id: 'quiz-right',
      color: 'primary',
      isSkipped: false,
      isCorrect: true,
      onClick: onAnswer('correct'),
    },
    {
      text: 'Vastasin väärin',
      id: 'quiz-wrong',
      color: 'danger',
      isSkipped: false,
      isCorrect: false,
      onClick: onAnswer('wrong'),
    },
    {
      text: 'Ohita',
      id: 'quiz-skip',
      color: 'secondary',
      isSkipped: true,
      isCorrect: false,
      onClick: onAnswer('skip'),
    },
  ];

  if (!question.answerFile || !question.questionFile) return <Loading />;

  const categoriesList = categories
    .filter((c) => question.categories.includes(c.id))
    .map((c) => c.name)
    .join(', ');
  const title = `Aiheet: ${categoriesList}`;

  return (
    <div className="row">
      <div className="col-lg-6">
        <Card header={<h4 className="text-white">{title}</h4>}>
          <div className="row">
            <div className="col">
              <table className="mb-3">
                <tbody>
                  <DetailField label="Kysymys">{question.name}</DetailField>
                  <DetailField label="Vaikeusaste">{question.difficulty}</DetailField>
                  <DetailField label="Oikein (kpl)">
                    {(question.correctAnswers || []).length}
                  </DetailField>
                  <DetailField label="Väärin (kpl)">
                    {(question.wrongAnswers || []).length}
                  </DetailField>
                  <DetailField label="Ohitettu (kpl)">
                    {(question.skipAnswers || []).length}
                  </DetailField>
                </tbody>
              </table>
              {buttons.map((b) => (
                <Button
                  key={b.id}
                  id={b.id}
                  className="mr-2"
                  onClick={b.onClick}
                  color={b.color as any}
                >
                  {b.text}
                </Button>
              ))}
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <img src={getFileUrl(question.questionFile)} alt="question" className="quiz-img" />
            </div>
          </div>
        </Card>
      </div>
      <div className="col-lg-6">
        <Card
          header={
            <h4 className="text-white">Kun olet vastannut kysymykseen, valitse seuraavista:</h4>
          }
        >
          <>
            <details className="my-2" key={`answer-${question.id}`}>
              <summary>Näytä vastaus</summary>
              <img src={getFileUrl(question.answerFile)} alt="answer" className="answer-img" />
            </details>
            {question.modelAnswerFile ? (
              <details className="my-2" key={`model-answer-${question.id}`}>
                <summary>Näytä malliratkaisu</summary>
                <img
                  src={getFileUrl(question.modelAnswerFile)}
                  alt="example-answer"
                  className="example-answer-img"
                />
              </details>
            ) : (
              ''
            )}
          </>
        </Card>
      </div>
    </div>
  );
};

export default QuizQuestion;
