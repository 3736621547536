import '@coreui/coreui/dist/css/coreui.min.css';
import * as Sentry from '@sentry/browser';
import merge from 'deepmerge';
import { createBrowserHistory } from 'history';
import i18next from 'i18next';
import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { initReactI18next } from 'react-i18next';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { routerMiddleware } from 'react-router-redux';
import { Rednot } from 'rednot';
import 'rednot/src/styles/index.css';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxThunk from 'redux-thunk';
import { releoxTranslations } from 'releox-react';
import 'releox-react/lib/releox.css';
import App from './App';
import config from './config';
import './index.css';
import TableOfContentEditSceneTranslation from './scenes/table-of-content/TableOfContentEditSceneTranslation';
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducer';
import isProduction from './utils/is-production';

/**
 * Init configs
 */
if (isProduction()) {
  if (config().ga) ReactGA.initialize(config().ga);
  if (config().sentry) Sentry.init({ dsn: config().sentry, ignoreErrors: [] });
}

/**
 * Bind routes to Google Analytics
 */
const history = createBrowserHistory();
if (config().ga && isProduction()) {
  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });
}

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(reduxThunk, routerMiddleware(history)))
);

i18next.use(initReactI18next).init({
  lng: 'fi',
  debug: true,
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  resources: merge<any>(releoxTranslations, TableOfContentEditSceneTranslation),
});

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
    <Rednot />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
