import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Button, Card } from 'releox-react';
import { ReduxDispatch } from '../interfaces/ReduxDispatch';
import {
  nextStep,
  prepareQuiz,
  prepareWrongQuestions,
  resetStep,
  resetStepPartially,
} from '../store/actions/quiz.actions';
import { ReduxStore } from '../store/reducer';

const QuizSummary = () => {
  const dispatch = useDispatch<ReduxDispatch>();
  const location = useLocation();

  const isProgressionMode = useSelector((state: ReduxStore) => state.quizEntry.isProgressionMode);
  const lastStep = useSelector((state: ReduxStore) => state.quizEntry.lastStep);
  const passScore = useSelector((state: ReduxStore) => state.quizEntry.passScore);
  const score = useSelector((state: ReduxStore) => state.quizEntry.score);

  const skipAnswerCounter = useSelector(
    ({ quizEntry }: ReduxStore) => quizEntry.skipAnswers.length
  );

  const wrongAnswerCounter = useSelector(
    ({ quizEntry }: ReduxStore) => quizEntry.wrongAnswers.length
  );

  const correctAnswerCounter = useSelector(
    ({ quizEntry }: ReduxStore) => quizEntry.correctAnswers.length
  );

  const onReset = useCallback(
    (force?: boolean) => {
      dispatch(prepareQuiz(location.search, force));
    },
    [dispatch, location.search]
  );

  const onResetStep = useCallback(() => {
    dispatch(resetStep());
  }, [dispatch]);

  const onResetStepPartially = useCallback(() => {
    dispatch(resetStepPartially());
  }, [dispatch]);

  const onNextStep = useCallback(() => {
    dispatch(nextStep());
  }, [dispatch]);

  const onContinue = useCallback(() => {
    dispatch(prepareWrongQuestions());
  }, [dispatch]);

  const noWrongOrSkippedQuestions = wrongAnswerCounter === 0 && skipAnswerCounter === 0;

  let title = 'Yhteenveto';
  let buttons = (
    <>
      <p>
        Valitse mitä haluat tehdä seuraavaksi. Voit aloittaa alusta tai kokeilla vastata väärin
        menneisiin ja ohitettuihin kysymyksiin:
      </p>
      <Button id="reset" className="mr-4" onClick={onReset}>
        Aloita alusta
      </Button>
      <Button id="continue" onClick={onContinue}>
        Jatka väärin menneisiin
      </Button>
    </>
  );

  if (noWrongOrSkippedQuestions) {
    buttons = (
      <>
        <p>Vastasit kaikkiin kysymyksiin oikein</p>
        <Button id="reset" className="mr-4" onClick={onReset}>
          Aloita alusta
        </Button>
      </>
    );
  }

  if (isProgressionMode) {
    title = 'Sait tarpeeksi pisteitä';
    if (passScore < score) {
      if (lastStep) {
        buttons = (
          <Button id="reset" className="mr-4" onClick={() => onReset(true)}>
            Aloita alusta
          </Button>
        );
      } else {
        buttons = (
          <>
            <p>Siirry seuraavaan vaiheeseen</p>
            <Button id="reset" className="mr-4" onClick={onNextStep}>
              Siirry seuraavaan vaiheeseen
            </Button>
          </>
        );
      }
    } else {
      title = 'Ei tarpeeksi pisteita';
      buttons = (
        <>
          <Button id="reset" className="mr-4" onClick={onResetStep}>
            Aloita sarja alusta
          </Button>
          <Button id="reset" className="mr-4" onClick={onResetStepPartially}>
            Vastaa uudellee väärin vastatuihin
          </Button>
        </>
      );
    }
  }

  return (
    <div className="row">
      <div className="col-lg-6 offset-lg-3">
        <Card>
          <h1>{title}</h1>
          <p>
            {`Vastasit ${correctAnswerCounter} kysymykseen oikein, ${wrongAnswerCounter} kysymykseen väärin ja ohitit ${skipAnswerCounter} kysymystä`}
          </p>
          {buttons}
        </Card>
      </div>
    </div>
  );
};

export default QuizSummary;
