import React, { useEffect } from 'react';
import { Card, CardTitle, Loading } from 'releox-react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ReduxStore } from '../store/reducer';
import { fetchQuestionAction } from '../store/actions/question.actions';
import { getFileUrl } from '../config';

export default () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const question = useSelector((state: ReduxStore) => state.questionEntry.model.data);

  useEffect(() => {
    const include = ['questionFile', 'answerFile', 'modelAnswerFile'];
    dispatch(fetchQuestionAction(id, { include }));
  }, [dispatch, id]);

  if (!question.answerFile || !question.questionFile) return <Loading />;
  return (
    <Card>
      <CardTitle>{question.name}</CardTitle>
      <div className="row">
        <div className="col-lg-4">
          <p>Kysymys</p>
          <img src={getFileUrl(question.questionFile)} alt="" />
        </div>
        <div className="col-lg-4">
          <p>Vastaus</p>
          <img src={getFileUrl(question.answerFile)} alt="" />
        </div>
        <div className="col-lg-4">
          {question.modelAnswerFile ? (
            <>
              <p>Esimerkkivastaus</p>
              <img src={getFileUrl(question.answerFile)} alt="" />
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </Card>
  );
};
