import { push } from 'react-router-redux';
import {
  actionCreator,
  GenericInitState,
  reducerCreator,
  requestCreator,
} from 'redux-rest-helper-for-loopback';
import { Answer } from '../../interfaces/Answer';
import URL from '../../URL';
import { showError } from './notif.actions';

const entry = 'ANSWER';

const actions = actionCreator(entry, requestCreator<Answer>(URL.AnswerAPI), push, showError);

export const createAnswerAction = actions.getCreateAction();
export const updateAnswerAction = actions.getUpdateAction();
export const fetchAnswerAction = actions.getFetchAction();
export const deleteAnswerAction = actions.getDeleteAction();
export const listAnswerAction = actions.getListAndCountAction();
export const cleanAnswerAction = actions.getCleanAction();

export const answerReducer = reducerCreator(entry, {}).getReducer();

export interface AnswerRedux extends GenericInitState<Answer> {}
