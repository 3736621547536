import {
  actionCreator,
  requestCreator,
  reducerCreator,
  GenericInitState,
} from 'redux-rest-helper-for-loopback';
import { push } from 'react-router-redux';
import URL from '../../URL';
import { showError } from './notif.actions';
import { Question } from '../../interfaces/Question';

const entry = 'QUESTION';

export const questionInitValues: Question = {
  id: '',
  name: '',
  questionFileId: '',
  answerFileId: '',
  modelAnswerFileId: '',
  difficulty: 1,
  categories: [],
};

const question = 'Haluatko varmasti poistaa tietueen?';

export const questionRequests = requestCreator<Question>(URL.QuestionAPI);

const actions = actionCreator(entry, questionRequests, push, showError);

export const createQuestionAction = actions.getCreateAction();
export const updateQuestionAction = actions.getUpdateAction(URL.QUESTION.INDEX);
export const fetchQuestionAction = actions.getFetchAction();
export const deleteQuestionAction = actions.getDeleteAction(URL.QUESTION.INDEX, question);
export const listQuestionAction = actions.getListAndCountAction();
export const cleanQuestionAction = actions.getCleanAction();

export const questionReducer = reducerCreator(entry, questionInitValues).getReducer();

export interface QuestionRedux extends GenericInitState<Question> {}
