import React from 'react';
import { Link } from 'react-router-dom';
import { DataTableColumn, GenericFormScene, GenericIndexScene } from 'releox-react';
import CategoryForm, { categoryInitValues, categoryValidationSchema } from '../forms/CategoryForm';
import { Category } from '../interfaces/Category';
import {
  createCategoryAction,
  fetchCategoryAction,
  listCategoryAction,
  updateCategoryAction,
} from '../store/actions/category.actions';
import URL from '../URL';

const columns: DataTableColumn[] = [
  {
    text: 'ID',
    dataField: 'id',
  },
  {
    text: 'Nimi',
    dataField: 'name',
  },
  {
    text: 'Actions',
    dataField: 'foo',
    formatter: (c: any, cat: Category) => (
      <Link to={URL.CATEGORY.EDIT.replace(':id', cat.id)}>Muokkaa</Link>
    ),
  },
];

const reduxEntry = 'categoryEntry';

export const CategoryIndexScene = () => (
  <GenericIndexScene
    title="Kategoriat"
    reduxEntry={reduxEntry}
    listAction={listCategoryAction}
    dataTableProps={{ columns }}
    createLink={URL.CATEGORY.CREATE}
  />
);

export const CategoryEditScene = () => {
  return (
    <GenericFormScene
      title="Muokkaa kategoriaa"
      EmbedForm={CategoryForm}
      validationSchema={categoryValidationSchema}
      initialValues={categoryInitValues}
      saveAction={updateCategoryAction}
      reduxEntry={reduxEntry}
      fetchAction={fetchCategoryAction}
    />
  );
};

export const CategoryCreateScene = () => (
  <GenericFormScene
    title="Lisää kategoria"
    EmbedForm={CategoryForm}
    validationSchema={categoryValidationSchema}
    initialValues={categoryInitValues}
    saveAction={createCategoryAction}
    reduxEntry={reduxEntry}
  />
);
