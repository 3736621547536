import Axios from 'axios';
import { FieldProps } from 'formik';
import React, { Component } from 'react';
import Select, { OptionTypeBase } from 'react-select';
import { Category } from '../interfaces/Category';
import URL from '../URL';

interface State {
  tags: OptionTypeBase[];
}

interface Props extends FieldProps {
  label: string;
}

export default class CategoriesField extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tags: [],
    };
  }

  componentDidMount() {
    this.fetchTags();
  }

  getValue() {
    const { tags } = this.state;
    const { field } = this.props;
    if (field.value) return tags.filter((t) => field.value.includes(t.value));
    return undefined;
  }

  fetchTags() {
    Axios.get(URL.CategoryAPI).then((r) =>
      this.setState({ tags: r.data.map((tag: Category) => ({ value: tag.id, label: tag.name })) })
    );
  }

  render() {
    const { tags } = this.state;
    const { form, label, field } = this.props;
    return (
      <div className="form-group row">
        <div className="col-md-3 col-form-label">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>{label}</label>
        </div>
        <div className="col-md-9">
          <Select
            onChange={(options: any) => {
              let values = [];
              if (options) values = options.map((o: any) => o.value);
              return form.setFieldValue(field.name, values);
            }}
            isMulti
            name={field.name}
            options={tags}
            value={this.getValue()}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
      </div>
    );
  }
}
