import deepMerge from 'deepmerge';
import { createReducer, reducerCreator } from 'redux-rest-helper-for-loopback';
import { Question } from '../../interfaces/Question';
import {
  CORRECT_ANSWER,
  COUNT_PASS_SCORE,
  COUNT_SCORE,
  LOAD_SAVE,
  NEXT_QUESTION,
  RESET,
  RESET_SCORE,
  RESET_SKIP_ANSWERS,
  RESET_WRONG_ANSWERS,
  SETUP_QUESTIONS,
  SET_LAST_STEP,
  SET_PROGRESS_ID,
  SET_SAVE_ID,
  SET_STEP,
  SKIP_ANSWER,
  START,
  WRONG_ANSWER,
} from '../action-types/quiz.action-types';
import { questionInitValues } from '../actions/question.actions';

export interface Action<T> {
  type: string;
  payload: T;
}

export const quizReducerInitState: QuizReducer = {
  currentQuestion: questionInitValues,
  questions: [],
  correctAnswers: [],
  skipAnswers: [],
  wrongAnswers: [],
  started: false,
  isProgressionMode: false,
  steps: [],
  currentStep: 0,
  score: 0,
  scoreLimit: 0,
  passScore: 0,
  categoryIds: [],
  lastStep: false,
  saveId: '',
  progressId: '',
};

const red = reducerCreator('@QUIZ', quizReducerInitState);
const handlers = red.getHandlers();

export interface NextQuestionPayload {
  questions: Question[];
  currentQuestion: Question;
}

const nextQuestion = (state: QuizReducer, action: Action<NextQuestionPayload>): QuizReducer => ({
  ...state,
  questions: action.payload.questions,
  currentQuestion: action.payload.currentQuestion,
});

export interface CorrectAnswerPayload {
  question: Question;
}

const correctAnswer = (state: QuizReducer, action: Action<CorrectAnswerPayload>): QuizReducer => ({
  ...state,
  correctAnswers: [...state.correctAnswers, action.payload.question],
});

export type WrongAnswerPayload = CorrectAnswerPayload;

const wrongAnswer = (state: QuizReducer, action: Action<WrongAnswerPayload>): QuizReducer => ({
  ...state,
  wrongAnswers: [...state.wrongAnswers, action.payload.question],
});

export type SkipAnswerPayload = CorrectAnswerPayload;

const skipAnswer = (state: QuizReducer, action: Action<SkipAnswerPayload>): QuizReducer => ({
  ...state,
  skipAnswers: [...state.skipAnswers, action.payload.question],
});

export interface SaveIdPayload {
  saveId: string;
}

const setSaveId = (state: QuizReducer, action: Action<SaveIdPayload>): QuizReducer => ({
  ...state,
  saveId: action.payload.saveId,
});

export interface SetupQuestionsPayload {
  questions: Question[];
}

const setupQuestions = (
  state: QuizReducer,
  action: Action<SetupQuestionsPayload>,
  init: QuizReducer
): QuizReducer => ({
  ...state,
  questions: action.payload.questions,
  wrongAnswers: init.wrongAnswers,
  skipAnswers: init.skipAnswers,
});

export interface Step {
  questions: Question[];
  categoryIds: string[];
}

export interface StartPayload {
  questions: Question[];
  categoryIds: string[];
  isProgressionMode?: boolean;
  steps?: Step[];
}

const start = (state: QuizReducer, { payload }: Action<StartPayload>): QuizReducer => ({
  ...state,
  ...payload,
  ...{ started: true },
});

export interface CountPassScorePayload {
  questions: Question[];
  scoreLimit: number;
}

const countPassScore = (
  state: QuizReducer,
  { payload }: Action<CountPassScorePayload>
): QuizReducer =>
  deepMerge<QuizReducer>(state, {
    passScore:
      (payload.questions.map((q) => q.difficulty).reduce((a, b) => a + b, 0) / 100) *
      payload.scoreLimit,
    scoreLimit: payload.scoreLimit,
  });

const reset = (__: QuizReducer, _: Action<StartPayload>, init: QuizReducer): QuizReducer => init;

export interface CountPayload {
  score: number;
}

const countScore = (state: QuizReducer, action: Action<CountPayload>): QuizReducer => ({
  ...state,
  score: state.score + action.payload.score,
});

export interface SetStepPayload {
  step: number;
}

const setStep = (state: QuizReducer, action: Action<SetStepPayload>): QuizReducer => ({
  ...state,
  currentStep: action.payload.step,
});

const resetScore = (state: QuizReducer): QuizReducer => ({
  ...state,
  score: 0,
  correctAnswers: [],
  wrongAnswers: [],
});

const setLastStep = (state: QuizReducer): QuizReducer => ({
  ...state,
  lastStep: true,
});

const resetSkipAnswers = (state: QuizReducer): QuizReducer => ({
  ...state,
  skipAnswers: [],
});

const resetWrongAnswers = (state: QuizReducer): QuizReducer => ({
  ...state,
  wrongAnswers: [],
});

const loadSave = (state: QuizReducer, action: Action<any>): QuizReducer => ({
  ...state,
  ...action.payload,
});

const setProgressId = (state: QuizReducer, action: Action<any>): QuizReducer => ({
  ...state,
  progressId: action.payload.progressId,
});

export default createReducer(
  {
    ...handlers,
    [CORRECT_ANSWER]: correctAnswer,
    [WRONG_ANSWER]: wrongAnswer,
    [NEXT_QUESTION]: nextQuestion,
    [START]: start,
    [SKIP_ANSWER]: skipAnswer,
    [RESET]: reset,
    [SETUP_QUESTIONS]: setupQuestions,
    [COUNT_SCORE]: countScore,
    [COUNT_PASS_SCORE]: countPassScore,
    [RESET_SCORE]: resetScore,
    [SET_STEP]: setStep,
    [SET_LAST_STEP]: setLastStep,
    [RESET_SKIP_ANSWERS]: resetSkipAnswers,
    [RESET_WRONG_ANSWERS]: resetWrongAnswers,
    [SET_SAVE_ID]: setSaveId,
    [LOAD_SAVE]: loadSave,
    [SET_PROGRESS_ID]: setProgressId,
  },
  quizReducerInitState
);

export interface QuizReducer {
  currentQuestion: Question;
  questions: Question[];
  wrongAnswers: Question[];
  skipAnswers: Question[];
  correctAnswers: Question[];
  started: boolean;
  filter?: any;
  isProgressionMode: boolean;
  steps: Step[];
  score: number;
  passScore: number;
  currentStep: number;
  scoreLimit: number;
  categoryIds: string[];
  lastStep: boolean;
  saveId: string;
  progressId: string;
}
