import { requestCreator } from 'redux-rest-helper-for-loopback';
import URL from '../../URL';
import TableOfContent from './TableOfContent';

export default class TableOfContentsEffect {
  public static restRequests = requestCreator<TableOfContent>(URL.TableOfContentAPI);

  public static findById = TableOfContentsEffect.restRequests.getById;

  public static find = TableOfContentsEffect.restRequests.getAll;

  public static create = TableOfContentsEffect.restRequests.create;

  public static update = TableOfContentsEffect.restRequests.update;

  public static delete = TableOfContentsEffect.restRequests.delete;

  public static count = TableOfContentsEffect.restRequests.count;
}
