import React from 'react';
import { useTranslation } from 'react-i18next';
import { GenericFormScene } from 'releox-react';
import TableOfContentForm from '../../forms/table-of-content/TableOfContentForm';
import TableOfContentInitialValues from '../../forms/table-of-content/TableOfContentInitialValues';
import TableOfContentValidationSchema from '../../forms/table-of-content/TableOfContentValidationSchema';
import TableOfContentsAction from '../../store/table-of-content/TableOfContentsAction';

export default () => {
  const { t } = useTranslation('TableOfContentEditScene');

  return (
    <GenericFormScene
      title={t('title')}
      EmbedForm={TableOfContentForm}
      validationSchema={TableOfContentValidationSchema}
      initialValues={TableOfContentInitialValues}
      saveAction={TableOfContentsAction.update}
      reduxEntry="tableOfContent"
      fetchAction={TableOfContentsAction.fetch}
    />
  );
};
