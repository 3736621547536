import Axios from 'axios';
import merge from 'deepmerge';
import queryString from 'query-string';
import { Progress } from '../../interfaces/Progress';
import { Question } from '../../interfaces/Question';
import { ReduxDispatch } from '../../interfaces/ReduxDispatch';
import URL from '../../URL';
import ActionUtility from '../../utils/ActionUtility';
import {
  CORRECT_ANSWER,
  COUNT_PASS_SCORE,
  COUNT_SCORE,
  LOAD_SAVE,
  NEXT_QUESTION,
  RESET,
  RESET_SCORE,
  RESET_SKIP_ANSWERS,
  RESET_WRONG_ANSWERS,
  SETUP_QUESTIONS,
  SET_LAST_STEP,
  SET_PROGRESS_ID,
  SET_SAVE_ID,
  SET_STEP,
  SKIP_ANSWER,
  START,
  WRONG_ANSWER,
} from '../action-types/quiz.action-types';
import ProgressSave from '../progress-save/ProgressSave';
import ProgressSavesEffect from '../progress-save/ProgressSavesEffect';
import { ReduxStore } from '../reducer';
import {
  Action,
  CountPassScorePayload,
  CountPayload,
  NextQuestionPayload,
  QuizReducer,
  SaveIdPayload,
  SetupQuestionsPayload,
  StartPayload,
  Step,
} from '../reducers/quiz.reducer';
import QuizSelector from '../selectors/QuizSelector';
import { listCategoryAction } from './category.actions';
import { showMessage } from './notif.actions';
import { progressRequests } from './progress.actions';
import { questionInitValues, questionRequests } from './question.actions';

export interface StartBody {
  categoryId: string;
  difficulty: number;
}

const wrong = { isCorrect: false, isSkipped: false };
const correct = { isCorrect: true, isSkipped: false };
const skip = { isCorrect: false, isSkipped: true };

const answerIncludes = (memberId: string): any => [
  {
    relation: 'skipAnswers',
    scope: {
      where: {
        ...skip,
        memberId,
      },
    },
  },
  {
    relation: 'wrongAnswers',
    scope: {
      where: {
        ...wrong,
        memberId,
      },
    },
  },
  {
    relation: 'correctAnswers',
    scope: {
      where: {
        ...correct,
        memberId,
      },
    },
  },
];

const getRandomInt = (max: number) => Math.floor(Math.random() * Math.floor(max));

const start = (
  questions: Question[],
  categoryIds: string[],
  isProgressionMode?: boolean,
  steps?: Step[]
): Action<StartPayload> => ({
  type: START,
  payload: { questions, isProgressionMode, steps, categoryIds },
});

const reset = () => ({
  type: RESET,
});

const nextQuestion = (
  currentQuestion: Question,
  questions: Question[]
): Action<NextQuestionPayload> => ({
  type: NEXT_QUESTION,
  payload: { currentQuestion, questions },
});

const resetWrongAnswer = () => ({
  type: RESET_WRONG_ANSWERS,
});

const resetSkipAnswer = () => ({
  type: RESET_SKIP_ANSWERS,
});

export const resetStepPartially = () => {
  return (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const { wrongAnswers, skipAnswers } = getState().quizEntry;
    const questions = [...wrongAnswers, ...skipAnswers];
    dispatch(nextQuestion(questions[0] || questionInitValues, questions.splice(1)));
    dispatch(resetWrongAnswer());
    dispatch(resetSkipAnswer());
  };
};

export const getNextQuestion = () => (dispatch: Function, getState: () => ReduxStore) => {
  const { questions, isProgressionMode } = getState().quizEntry;
  let currentQuestion;
  let leftQuestion: Question[];
  if (questions.length) {
    const index = getRandomInt(questions.length);
    currentQuestion = questions[index];
    leftQuestion = questions.filter((_, i) => i !== index);
  } else {
    currentQuestion = questionInitValues;
    leftQuestion = [];
  }
  dispatch(nextQuestion(currentQuestion, leftQuestion));
  if (isProgressionMode) {
    const noMoreQuestions = QuizSelector.selectNoMoreQuestions(getState());
    if (noMoreQuestions) dispatch(resetStepPartially());
  }
};

const countPassScore = (scoreLimit: number, questions: Question[]): Action<CountPassScorePayload> =>
  ActionUtility.createAction<CountPassScorePayload>(COUNT_PASS_SCORE, {
    scoreLimit,
    questions,
  });

const questionIncludes = (memberId: string) => [
  'questionFile',
  'answerFile',
  'modelAnswerFile',
  ...answerIncludes(memberId),
];

const setSaveId = (saveId: string): Action<SaveIdPayload> => ({
  type: SET_SAVE_ID,
  payload: { saveId },
});

const fetchProgress = (id: string) => {
  return progressRequests.getById(id);
};

const fetchProgressSteps = (progression: Progress, memberId: string) => {
  return progression.steps.map((p) => {
    const filter = {
      include: questionIncludes(memberId),
      where: { and: p.map((c: string) => ({ categories: { like: c } })) },
    };
    return questionRequests.getAll(filter);
  });
};

const mapStepPromisesToStep = (progression: Progress, res: Question[][]) =>
  res.map((r, index) => ({
    questions: r,
    categoryIds: progression.steps[index],
  }));

const mapStateToProgressSave = (state: ReduxStore): ProgressSave => {
  const { user, quizEntry } = state;
  return {
    id: '',
    progressId: quizEntry.progressId,
    memberId: user.model.data.id,
    currentQuestion: quizEntry.currentQuestion.id,
    questions: quizEntry.questions.map((q) => q.id),
    wrongAnswers: quizEntry.wrongAnswers.map((q) => q.id),
    skipAnswers: quizEntry.skipAnswers.map((q) => q.id),
    correctAnswers: quizEntry.correctAnswers.map((q) => q.id),
    started: quizEntry.started,
    filter: quizEntry.filter,
    isProgressionMode: quizEntry.isProgressionMode,
    score: quizEntry.score,
    passScore: quizEntry.passScore,
    currentStep: quizEntry.currentStep,
    scoreLimit: quizEntry.scoreLimit,
    categoryIds: quizEntry.categoryIds,
    lastStep: quizEntry.lastStep,
  };
};

const mapProgressSaveToState = (progressSave: ProgressSave): Promise<QuizReducer> => {
  const getQuery = (arr: string[]) => ({
    where: { id: { inq: arr } },
    include: questionIncludes(progressSave.memberId),
  });
  const currentQuestionReq = questionRequests.getById(progressSave.currentQuestion, {
    include: questionIncludes(progressSave.memberId),
  });

  const questionsReq = questionRequests.getAll(getQuery(progressSave.questions));
  const wrongAnswersReq = questionRequests.getAll(getQuery(progressSave.wrongAnswers));
  const skipAnswersReq = questionRequests.getAll(getQuery(progressSave.skipAnswers));
  const correctAnswersReq = questionRequests.getAll(getQuery(progressSave.correctAnswers));

  return Promise.all([
    currentQuestionReq,
    questionsReq,
    wrongAnswersReq,
    skipAnswersReq,
    correctAnswersReq,
  ])
    .then(([currentQuestion, questions, wrongAnswers, skipAnswers, correctAnswers]) => {
      return Promise.resolve({
        currentQuestion,
        questions,
        wrongAnswers,
        skipAnswers,
        correctAnswers,
        started: progressSave.started,
        filter: progressSave.filter,
        isProgressionMode: progressSave.isProgressionMode,
        steps: [],
        score: progressSave.score,
        passScore: progressSave.passScore,
        currentStep: progressSave.currentStep,
        scoreLimit: progressSave.scoreLimit,
        categoryIds: progressSave.categoryIds,
        lastStep: progressSave.lastStep,
        saveId: progressSave.id,
        progressId: progressSave.progressId,
      });
    })
    .then((r) => {
      return new Promise((resolve) => {
        fetchProgress(progressSave.progressId).then((progression) => {
          const stepsPromises = fetchProgressSteps(progression, progressSave.memberId);
          Promise.all(stepsPromises).then((res) => {
            const steps: Step[] = mapStepPromisesToStep(progression, res);
            return resolve({ ...r, steps });
          });
        });
      });
    });
};

const removeAndCreateSave = (progressId: string) => {
  return (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const memberId = getState().user.model.data.id;
    ProgressSavesEffect.find({ where: { memberId, progressId } })
      .then((saves) => {
        saves.map((s) => ProgressSavesEffect.delete(s.id));
        const state = getState();
        return ProgressSavesEffect.create(mapStateToProgressSave(state));
      })
      .then((save) => {
        dispatch(setSaveId(save.id));
      });
  };
};

const loadProgress = (id: string) => {
  return (dispatch: ReduxDispatch) => {
    ProgressSavesEffect.findById(id).then((save) => {
      dispatch(setSaveId(id));
      mapProgressSaveToState(save).then((state) => {
        dispatch(ActionUtility.createAction(LOAD_SAVE, state));
      });
    });
  };
};

const setProgressionId = (progressId: string) => ({
  type: SET_PROGRESS_ID,
  payload: { progressId },
});

const prepareProgressionMode = (query: any, force = false) => {
  return (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const memberId = getState().user.model.data.id;
    if (query.saveId && force === false) {
      dispatch(loadProgress(query.saveId));
    } else {
      dispatch(setProgressionId(query.progression));
      fetchProgress(query.progression).then((progression) => {
        const stepsPromises = fetchProgressSteps(progression, memberId);
        Promise.all(stepsPromises).then((res) => {
          const steps: Step[] = mapStepPromisesToStep(progression, res);
          if (progression.steps.length === 1)
            dispatch(ActionUtility.createAction(SET_LAST_STEP, {}));
          dispatch(start(res[0], progression.steps[0], true, steps));
          dispatch(countPassScore(progression.scoreLimit, res[0]));
          dispatch(getNextQuestion());
          dispatch(removeAndCreateSave(query.progression));
        });
      });
    }
  };
};

const prepareCategoryMode = (query: any) => {
  return (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const memberId = getState().user.model.data.id;
    let where;

    if (query.categories) {
      where = { and: query.categories.map((c: string) => ({ categories: { like: c } })) };
    } else {
      where = { id: query.question };
    }

    const filter = {
      include: questionIncludes(memberId),
      where,
    };
    questionRequests.getAll(filter).then((questions) => {
      let categories = [];
      if (query.categories) categories = query.categories;
      dispatch(start(questions, categories));
      dispatch(getNextQuestion());
    });
  };
};

export const prepareQuiz = (search: string, force?: boolean) => (dispatch: ReduxDispatch) => {
  dispatch(reset());
  const query: any = queryString.parse(search, { arrayFormat: 'bracket' });
  if (query.progression) {
    dispatch(prepareProgressionMode(query, force));
  } else {
    dispatch(prepareCategoryMode(query));
  }
  dispatch(listCategoryAction());
};

export type AnswerType = 'correct' | 'wrong' | 'skip';
export type AnswerActionType = '@QUIZ:CORRECT_ANSWER' | '@QUIZ:WRONG_ANSWER' | '@QUIZ:SKIP_ANSWER';

const answer = (type: AnswerActionType, question: Question) =>
  ActionUtility.createAction(type, { question });

const countScore = (score: number) =>
  ActionUtility.createAction<CountPayload>(COUNT_SCORE, { score });

const saveProgress = () => {
  return (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    if (state.quizEntry.currentQuestion) {
      ProgressSavesEffect.update(state.quizEntry.saveId, mapStateToProgressSave(state));
    }
  };
};

export const answerAction = (event: AnswerType, question: Question) => (
  dispatch: ReduxDispatch,
  getState: () => ReduxStore
) => {
  const memberId = getState().user.model.data.id;
  let actionType: AnswerActionType;
  let message;
  let isSkipped = false;
  let isCorrect = false;
  if (event === 'correct') {
    isCorrect = true;
    actionType = CORRECT_ANSWER;
    message = 'Vastaus oli oikein';
  } else if (event === 'wrong') {
    actionType = WRONG_ANSWER;
    message = 'Vastaus oli väärin';
  } else {
    isSkipped = true;
    actionType = SKIP_ANSWER;
    message = 'Ohitit vastauksen';
  }
  if (getState().quizEntry.isProgressionMode && event === 'correct')
    dispatch(countScore(question.difficulty));
  Axios.post(URL.AnswerAPI, {
    isSkipped,
    isCorrect,
    questionId: question.id,
    memberId,
  });
  dispatch(answer(actionType, question));
  dispatch(showMessage(message));
  dispatch(getNextQuestion());
  if (getState().quizEntry.saveId) {
    dispatch(saveProgress());
  }
};

export const prepareWrongQuestions = () => (
  dispatch: ReduxDispatch,
  getState: () => ReduxStore
) => {
  const { wrongAnswers, skipAnswers } = getState().quizEntry;
  dispatch(
    ActionUtility.createAction<SetupQuestionsPayload>(SETUP_QUESTIONS, {
      questions: merge(wrongAnswers, skipAnswers),
    })
  );
  dispatch(getNextQuestion());
};

export const nextStep = () => (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
  const { steps, currentStep, scoreLimit } = getState().quizEntry;
  const nStep = currentStep + 1;
  if (nStep + 1 === steps.length) dispatch(ActionUtility.createAction(SET_LAST_STEP, {}));
  dispatch(ActionUtility.createAction(RESET_SCORE, {}));
  dispatch(start(steps[nStep].questions, steps[nStep].categoryIds, true, steps));
  dispatch(ActionUtility.createAction(SET_STEP, { step: nStep }));
  dispatch(countPassScore(scoreLimit, steps[nStep].questions));
  dispatch(getNextQuestion());
};

export const resetStep = () => (dispatch: Function, getState: () => ReduxStore) => {
  const { steps, currentStep } = getState().quizEntry;
  // reset score
  dispatch(ActionUtility.createAction(RESET_SCORE, {}));
  dispatch(start(steps[currentStep].questions, steps[currentStep].categoryIds, true, steps));
  dispatch(getNextQuestion());
};
