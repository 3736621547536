import { Field } from 'formik';
import React from 'react';
import { Input } from 'releox-react';
import * as Yup from 'yup';
import CategoriesField from '../components/CategoryField';
import FileInput from '../components/FileInput';

export const questionValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
  difficulty: Yup.number().required(),
  questionFileId: Yup.string().required(),
  answerFileId: Yup.string().required(),
});

const QuestionForm = () => (
  <>
    <Input inline inlineLabelWidth={3} label="Nimi" name="name" />
    <Input inline inlineLabelWidth={3} label="Vaikeustaso" type="number" name="difficulty" />
    <Field name="categories" component={CategoriesField} />
    <Field
      name="questionFileId"
      label="Kysymys"
      bucket={window.S3_CONTAINER}
      component={FileInput}
    />
    <Field name="answerFileId" label="Vastaus" bucket={window.S3_CONTAINER} component={FileInput} />
    <Field
      name="modelAnswerFileId"
      label="Mallivastaus"
      bucket={window.S3_CONTAINER}
      component={FileInput}
    />
  </>
);

export default QuestionForm;
