import queryString from 'query-string';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import { listProgressAction } from '../../store/actions/progress.actions';
import { ReduxStore } from '../../store/reducer';
import URL from '../../URL';
import { ModeCard } from './ModeIndexScene';

export default () => {
  const dispatch = useDispatch();
  const progressions = useSelector((store: ReduxStore) => store.progressEntry.list.data);
  const user = useSelector((store: ReduxStore) => store.user.model.data);

  useEffect(() => {
    const include: any[] = [
      {
        relation: 'progressSaves',
        scope: {
          where: { memberId: user.id },
        },
      },
    ];
    dispatch(listProgressAction({ include }));
  }, [dispatch, user]);

  const onSubmit = useCallback(
    (progression: string) => () => {
      const url = `${URL.MODE.QUIZ.replace(':mode', 'progression')}?${queryString.stringify({
        progression,
      })}`;
      dispatch(push(url));
    },
    [dispatch]
  );

  const onContinue = useCallback(
    (progression: string, saveId: string) => () => {
      const url = `${URL.MODE.QUIZ.replace(':mode', 'progression')}?${queryString.stringify({
        progression,
      })}&saveId=${saveId}`;
      dispatch(push(url));
    },
    [dispatch]
  );

  return (
    <div className="row">
      {progressions.map((p) => {
        if (!p.progressSaves) return '';
        const saveId = p.progressSaves.length ? p.progressSaves[0].id : false;
        return (
          <ModeCard
            key={p.id}
            title={p.name}
            onClick={onSubmit(p.id)}
            onContinue={saveId ? onContinue(p.id, saveId) : undefined}
          />
        );
      })}
    </div>
  );
};
