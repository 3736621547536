import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Loading } from 'releox-react';
import { ReduxDispatch } from '../interfaces/ReduxDispatch';
import { prepareQuiz } from '../store/actions/quiz.actions';
import { ReduxStore } from '../store/reducer';
import QuizSelector from '../store/selectors/QuizSelector';
import QuizQuestion from './QuizQuestion';
import QuizSummary from './QuizSummary';

const Quiz = () => {
  const dispatch = useDispatch<ReduxDispatch>();
  const location = useLocation();

  const started = useSelector((state: ReduxStore) => state.quizEntry.started);
  const passScore = useSelector((state: ReduxStore) => state.quizEntry.passScore);
  const score = useSelector((state: ReduxStore) => state.quizEntry.score);
  const noMoreQuestions = useSelector(QuizSelector.selectNoMoreQuestions);

  useEffect(() => {
    dispatch(prepareQuiz(location.search));
  }, [dispatch, location]);

  if (!started) return <Loading />;
  if (noMoreQuestions || passScore < score) return <QuizSummary />;
  return <QuizQuestion />;
};

export default Quiz;
