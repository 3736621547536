import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardTitle, Details } from 'releox-react';
import { ReduxStore } from '../store/reducer';

export default () => {
  const user = useSelector((state: ReduxStore) => state.user.model.data);
  return (
    <div className="row">
      <div className="col-lg-6 offset-lg-3">
        <Card>
          <CardTitle>Profiili</CardTitle>
          <Details
            object={user}
            properties={[
              {
                text: 'Nimi',
                dataField: 'name',
              },
              {
                text: 'Sähköposti',
                dataField: 'email',
              },
            ]}
          />
        </Card>
      </div>
    </div>
  );
};
