import { notifSend } from 'rednot';
import { getErrorMessage } from 'releox-react';

const dismissAfter = 3500;

const notif = (className: string) => (message: string) => (dispatch: Function) => {
  dispatch(notifSend({ message, dismissAfter, className }));
};

export const showMessage = notif('alert alert-primary');
export const showErrorMessage = notif('alert alert-danger');
export const showError = (e: Error, dispatch: Function) =>
  dispatch(notif('alert alert-danger')(getErrorMessage(e)));
