import React from 'react';
import * as Yup from 'yup';
import { Input, CoreuiCheckBox } from 'releox-react';
import { User } from '../interfaces/User';
import validationSchemaCreator from '../utils/validation-schema-creator';

export const memberValidationSchema = validationSchemaCreator({
  name: Yup.string().required('Nimi vaaditaa'),
  email: Yup.string()
    .email()
    .required(),
});

export const memberInvitationValidationSchema = validationSchemaCreator({
  email: Yup.string()
    .email()
    .required(),
});

export const memberInitValues: User = {
  id: '',
  name: '',
  email: '',
  isAdmin: false,
};

export const memberInvitationInitValues = {
  email: '',
};

export default () => (
  <>
    <Input inline inlineLabelWidth={3} label="Nimi" name="name" />
    <Input inline inlineLabelWidth={3} label="Email" name="email" />
    <CoreuiCheckBox label="Jarjestelmanvalvoja" name="isAdmin" />
  </>
);

export const MemberInvitationForm = () => (
  <>
    <Input inline inlineLabelWidth={3} label="Email" name="email" />
  </>
);
