const generateApi = (url: string) => `${window.API_ENDPOINT}${url}`;

export default {
  LOGOUT: '/logout',
  ANSWER: '/answers',
  TABLE_OF_CONTENT: {
    INDEX: '/table-of-contents',
    EDIT: '/table-of-contents/:id',
  },
  USERS: {
    INDEX: '/users',
    INVITE: '/users/invite',
    EDIT: '/users/:id/edit',
  },
  CATEGORY: {
    INDEX: '/categories',
    CREATE: '/categories/new',
    EDIT: '/categories/:id/edit',
  },
  PROGRESS: {
    INDEX: '/progress',
    CREATE: '/progress/new',
    EDIT: '/progress/:id/edit',
  },
  PROFILE: '/profile',
  QUESTION: {
    INDEX: '/questions',
    CREATE: '/questions/new',
    SHOW: '/questions/:id',
    EDIT: '/questions/:id/edit',
  },
  MODE: {
    INDEX: '/',
    SHOW: '/modes/:mode',
    HOUR_FILTER: '/modes/hour',
    PROGRESS_SELECT: '/modes/progress',
    QUIZ: '/modes/:mode/quiz',
  },
  AnswerAPI: generateApi('/Answers'),
  MemberAPI: generateApi('/Members'),
  CategoryAPI: generateApi('/Categories'),
  ContainerAPI: generateApi('/Containers'),
  QuestionAPI: generateApi('/Questions'),
  ProgressAPI: generateApi('/Progresses'),
  TableOfContentAPI: generateApi('/table-of-contents'),
  ProgressSaveAPI: generateApi('/ProgressSaves'),
};
