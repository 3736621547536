import React from 'react';
import { DataTableColumn, GenericFormScene, GenericIndexScene } from 'releox-react';
import URL from '../URL';
import MemberForm, {
  MemberInvitationForm,
  memberValidationSchema,
  memberInitValues,
  memberInvitationValidationSchema,
  memberInvitationInitValues,
} from '../forms/MemberForm';
import {
  listMemberAction,
  updateMemberAction,
  fetchMemberAction,
  inviteMemberAction,
} from '../store/actions/member.actions';

const columns: DataTableColumn[] = [
  {
    text: 'Nimi',
    dataField: 'name',
  },
  {
    text: 'Sähköposti',
    dataField: 'email',
  },
];

const reduxEntry = 'memberEntry';

export const MemberIndexScene = () => (
  <GenericIndexScene
    title="Käyttäjät"
    reduxEntry={reduxEntry}
    listAction={listMemberAction}
    dataTableProps={{ columns }}
    redirectUrl={URL.USERS.EDIT}
    createLink={URL.USERS.INVITE}
  />
);

export const MemberEditScene = () => (
  <GenericFormScene
    title="Muokkaa käyttäjän tietoja"
    EmbedForm={MemberForm}
    validationSchema={memberValidationSchema}
    initialValues={memberInitValues}
    saveAction={updateMemberAction}
    reduxEntry={reduxEntry}
    fetchAction={fetchMemberAction}
  />
);

export const MemberCreateScene = () => (
  <GenericFormScene
    title="Kutsu käyttäjiä"
    EmbedForm={MemberInvitationForm}
    validationSchema={memberInvitationValidationSchema}
    initialValues={memberInvitationInitValues}
    saveAction={inviteMemberAction}
    reduxEntry={reduxEntry}
  />
);
