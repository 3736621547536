import { CoreuiSidebarMenu } from 'releox-react/lib/coreui-layout/CoreuiSidebar';
import { User } from './interfaces/User';
import URL from './URL';

const sidebarMenu = (user: User): CoreuiSidebarMenu[] => {
  const routes: any[] = [
    {
      text: 'Moodit',
      exact: true,
      url: URL.MODE.INDEX,
    },
    {
      text: 'Oma tili',
      exact: true,
      url: URL.PROFILE,
    },
    {
      text: 'Vastaukset',
      exact: true,
      url: URL.ANSWER,
    },
  ];
  if (user.isAdmin) {
    routes.push({
      text: 'Käyttäjät',
      url: URL.USERS.INDEX,
    });
    routes.push({
      text: 'Sisällysluettelo',
      url: URL.TABLE_OF_CONTENT.INDEX,
    });
    routes.push({
      text: 'Progressit',
      url: URL.PROGRESS.INDEX,
    });
    routes.push({
      text: 'Kategoriat',
      url: URL.CATEGORY.INDEX,
    });
  }
  routes.push(
    {
      text: 'Kysymykset',
      url: URL.QUESTION.INDEX,
    },
    {
      text: 'Kirjaudu ulos',
      exact: true,
      url: URL.LOGOUT,
    }
  );
  return routes;
};

export default sidebarMenu;
