import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardTitle, Loading } from 'releox-react';
import TableOfContentsAction from '../store/table-of-content/TableOfContentsAction';
import TableOfContentsSelector from '../store/table-of-content/TableOfContentsSelector';
import URL from '../URL';

interface Item {
  title: string;
  tagID: string[];
  children?: Item[];
}

const ListItem = ({ item }: { item: Item }) => {
  return (
    <li>
      <Link
        to={`${URL.MODE.QUIZ.replace(':mode', 'hour')}?${item.tagID
          .map((tag) => `categories[]=${tag}`)
          .join('&')}`}
      >
        {item.title}
      </Link>
      {item.children && item.children.length ? (
        <ul>
          {item.children.map((child) => (
            <ul key={child.title}>
              <ListItem item={child} />
            </ul>
          ))}
        </ul>
      ) : (
        ''
      )}
    </li>
  );
};

export default () => {
  const dispatch = useDispatch();
  const tableOfContent = useSelector(TableOfContentsSelector.selectTableOfContent);

  useEffect(() => {
    dispatch(TableOfContentsAction.list());
  }, [dispatch]);

  if (!tableOfContent[0]) return <Loading />;
  return (
    <div className="content">
      <Card>
        <CardTitle>Sisällysluettelo</CardTitle>
        <ul>
          {tableOfContent[0].content.map((item: any) => (
            <ListItem item={item} key={item.title} />
          ))}
        </ul>
      </Card>
    </div>
  );
};
