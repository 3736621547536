import Axios, { AxiosError } from 'axios';
import {
  requestCreator,
  actionCreator,
  reducerCreator,
  GenericInitState,
  createReducer,
  createInitState,
} from 'redux-rest-helper-for-loopback';
import { push } from 'react-router-redux';
import { userFormInitValues } from './user.actions';
import URL from '../../URL';
import { showError } from './notif.actions';
import { User } from '../../interfaces/User';

export const INVITE_MEMBER_REQUEST = '@MEMBER:INVITE_MEMBER_REQUEST';
export const INVITE_MEMBER_FAIL = '@MEMBER:INVITE_MEMBER_FAIL';
export const INVITE_MEMBER_SUCCESS = '@MEMBER:INVITE_MEMBER_SUCCESS';
export const REMOVE_MEMBER_REQUEST = '@MEMBER:REMOVE_MEMBER_REQUEST';
export const REMOVE_MEMBER_FAIL = '@MEMBER:REMOVE_MEMBER_FAIL';
export const REMOVE_MEMBER_SUCCESS = '@MEMBER:REMOVE_MEMBER_SUCCESS';

const inviteMemberRequest = () => ({
  type: INVITE_MEMBER_REQUEST,
});

const inviteMemberFail = () => ({
  type: INVITE_MEMBER_FAIL,
});

const inviteMemberSuccess = () => ({
  type: INVITE_MEMBER_SUCCESS,
});

export const inviteMemberAction = ({ email }: { email: string }) => {
  return (dispatch: Function) => {
    dispatch(inviteMemberRequest());
    return Axios.post(`${URL.MemberAPI}/invite`, { email })
      .then(() => {
        dispatch(inviteMemberSuccess());
        dispatch(push(URL.USERS.INDEX));
      })
      .catch((e: AxiosError) => {
        dispatch(inviteMemberFail());
        showError(e, dispatch);
      });
  };
};

const name = 'MEMBER';
const apiURL = URL.MemberAPI;
const question = 'Haluatko varmasti poistaa käyttäjän?';

const rc = requestCreator<User>(apiURL);
const ac = actionCreator<User>(name, rc, push, showError);

const memberHandlers = reducerCreator(name, userFormInitValues).getHandlers();

export const memberReducer = createReducer(
  {
    ...memberHandlers,
    [INVITE_MEMBER_REQUEST]: (state: GenericInitState<User>) => ({
      ...state,
      model: { ...state.model, isLoading: true },
    }),
    [INVITE_MEMBER_FAIL]: (state: GenericInitState<User>) => ({
      ...state,
      model: { ...state.model, isLoading: false },
    }),
    [INVITE_MEMBER_SUCCESS]: (state: GenericInitState<User>) => ({
      ...state,
      model: { ...state.model, isLoading: false },
    }),
  },
  createInitState(userFormInitValues)
);

export const listMemberAction = ac.getListAndCountAction();
export const updateMemberAction = ac.getUpdateAction(URL.USERS.INDEX);
export const fetchMemberAction = ac.getFetchAction();
export const deleteMemberAction = ac.getDeleteAction(URL.USERS.INDEX, question);

export interface MemberRedux extends GenericInitState<User> {}
