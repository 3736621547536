import React from 'react';
import { DataTableColumn, GenericIndexScene, GenericFormScene } from 'releox-react';
import URL from '../URL';
import ProgressForm, { progressInitValues, progressValidationSchema } from '../forms/ProgressForm';
import {
  createProgressAction,
  updateProgressAction,
  fetchProgressAction,
  listProgressAction,
} from '../store/actions/progress.actions';

const columns: DataTableColumn[] = [
  {
    text: 'Nimi',
    dataField: 'name',
  },
  {
    text: 'Vaiheet',
    dataField: 'steps.length',
  },
];

const reduxEntry = 'progressEntry';

export const ProgressIndexScene = () => (
  <GenericIndexScene
    title="Progressit"
    reduxEntry={reduxEntry}
    listAction={listProgressAction}
    dataTableProps={{ columns }}
    redirectUrl={URL.PROGRESS.EDIT}
    createLink={URL.PROGRESS.CREATE}
  />
);

export const ProgressEditScene = () => (
  <GenericFormScene
    title="Muokkaa Progressia"
    EmbedForm={ProgressForm}
    validationSchema={progressValidationSchema}
    initialValues={progressInitValues}
    saveAction={updateProgressAction}
    reduxEntry={reduxEntry}
    fetchAction={fetchProgressAction}
  />
);

export const ProgressCreateScene = () => (
  <GenericFormScene
    title="Lisää Progressi"
    EmbedForm={ProgressForm}
    validationSchema={progressValidationSchema}
    initialValues={progressInitValues}
    saveAction={createProgressAction}
    reduxEntry={reduxEntry}
  />
);
