import { Field, FieldArray, FieldArrayRenderProps } from 'formik';
import React from 'react';
import { Input } from 'releox-react';
import * as Yup from 'yup';
import CategoriesField from '../components/CategoryField';
import { Progress } from '../interfaces/Progress';

export const progressValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
  scoreLimit: Yup.string().required(),
});

export const progressInitValues: Progress = {
  id: '',
  steps: [[]],
  name: '',
  scoreLimit: 0,
};

export default () => {
  return (
    <>
      <Input name="name" label="Nimi" />
      <Input name="scoreLimit" label="Raja (%)" />
      <FieldArray
        name="steps"
        render={(arrayHelpers: FieldArrayRenderProps) => {
          const { form } = arrayHelpers;
          const { steps }: { steps: string[][] } = form.values;
          let content;
          if (steps && steps.length > 0) {
            content = steps.map((step, index) => (
              <div className="row">
                <div className="col-md-8">
                  <Field
                    name={`steps.${index}`}
                    component={CategoriesField}
                    label={`Vaihe ${index + 1}`}
                  />
                </div>
                <div className="col-md-4">
                  <button
                    type="button"
                    onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                  >
                    -
                  </button>
                </div>
              </div>
            ));
          }
          return (
            <div className="row">
              <div className="col">
                {content}

                <button
                  type="button"
                  className="btn btn-primary mb-3 btn-block"
                  onClick={() => arrayHelpers.push([])} // insert an empty string at a position
                >
                  Lisää vaihe
                </button>
              </div>
            </div>
          );
        }}
      />
    </>
  );
};
