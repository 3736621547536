import {
  actionCreator,
  requestCreator,
  reducerCreator,
  GenericInitState,
} from 'redux-rest-helper-for-loopback';
import { push } from 'react-router-redux';
import URL from '../../URL';
import { Category } from '../../interfaces/Category';
import { categoryInitValues } from '../../forms/CategoryForm';
import { showError } from './notif.actions';

const entry = 'CATEGORY';

export const categoryRequests = requestCreator<Category>(URL.CategoryAPI);

const actions = actionCreator(entry, categoryRequests, push, showError);

export const createCategoryAction = actions.getCreateAction(URL.CATEGORY.INDEX);
export const updateCategoryAction = actions.getUpdateAction(URL.CATEGORY.INDEX);
export const fetchCategoryAction = actions.getFetchAction();
export const deleteCategoryAction = actions.getDeleteAction(URL.CATEGORY.INDEX);
export const listCategoryAction = actions.getListAndCountAction();
export const cleanCategoryAction = actions.getCleanAction();

export const categoryReducer = reducerCreator(entry, categoryInitValues).getReducer();

export interface CategoryRedux extends GenericInitState<Category> {}
