import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { routeMapper, getAuthRoutes, LoginScene, guestMiddleware } from 'releox-react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import DashboardRouter from './DashboardRouter';
import { showErrorMessage } from './store/actions/notif.actions';

interface Props {
  onError: (message: string) => void;
}

const Logo = () => (
  <div id="login-logo">
    <h2>Tampereen</h2>
    <h1>Kandidaattikoulutus</h1>
  </div>
);

const LoginView = guestMiddleware(LoginScene);

const App = ({ onError }: Props) => (
  <Switch>
    <Route
      path="/login"
      render={() => <LoginView onError={(e: Error) => onError(e.message)} titleBlock={<Logo />} />}
    />
    {getAuthRoutes().map(routeMapper)}
    <Route path="/" component={DashboardRouter} />
  </Switch>
);

const mapDispatchToProps = (dispatch: ThunkDispatch<void, void, Action>) => ({
  onError: (message: string) => dispatch(showErrorMessage(message)),
});

export const AppComponent = App;
export const appMapDispatchToProps = mapDispatchToProps;
export default connect(null, mapDispatchToProps)(App);
