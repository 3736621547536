export const WRONG_ANSWER = '@QUIZ:WRONG_ANSWER';
export const SKIP_ANSWER = '@QUIZ:SKIP_ANSWER';
export const CORRECT_ANSWER = '@QUIZ:CORRECT_ANSWER';
export const NEXT_QUESTION = '@QUIZ:NEXT_QUESTION';
export const START = '@QUIZ:START';
export const RESET = '@QUIZ:RESET';
export const SETUP_QUESTIONS = '@QUIZ:SETUP_QUESTIONS';
export const COUNT_SCORE = '@QUIZ:COUNT_SCORE';
export const COUNT_PASS_SCORE = '@QUIZ:COUNT_PASS_SCORE';
export const RESET_SCORE = '@QUIZ:RESET_SCORE';
export const SET_STEP = '@QUIZ:SET_STEP';
export const SET_LAST_STEP = '@QUIZ:SET_LAST_STEP';
export const RESET_WRONG_ANSWERS = '@QUIZ:RESET_WRONG_ANSWERS';
export const RESET_SKIP_ANSWERS = '@QUIZ:RESET_SKIP_ANSWERS';
export const SET_SAVE_ID = '@QUIZ:SET_SAVE_ID';
export const LOAD_SAVE = '@QUIZ:LOAD_SAVE';
export const SET_PROGRESS_ID = '@QUIZ:SET_PROGRESS_ID';
