import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import {
  Button,
  Card,
  DataTableColumn,
  GenericFormScene,
  GenericIndexScene,
  Input,
} from 'releox-react';
import QuestionForm, { questionValidationSchema } from '../forms/QuestionForm';
import { Category } from '../interfaces/Category';
import { ReduxDispatch } from '../interfaces/ReduxDispatch';
import { listCategoryAction } from '../store/actions/category.actions';
import {
  deleteQuestionAction,
  fetchQuestionAction,
  listQuestionAction,
  questionInitValues,
  updateQuestionAction,
} from '../store/actions/question.actions';
import { ReduxStore } from '../store/reducer';
import URL from '../URL';

const reduxEntry = 'questionEntry';

export const QuestionIndexScene = () => {
  const dispatch = useDispatch<ReduxDispatch>();
  const isAdmin = useSelector((state: ReduxStore) => state.user.model.data.isAdmin);
  const categories = useSelector((state: ReduxStore) => state.categoryEntry.list.data);
  const [where, setWhere] = useState<any>();

  useEffect(() => {
    dispatch(listCategoryAction());
  }, [dispatch]);

  const onSubmit = useCallback(({ q }: { q: string }) => {
    setWhere({ name: { like: q, options: 'i' } });
  }, []);

  const columns: DataTableColumn[] = [
    {
      text: 'Nimi',
      sort: true,
      dataField: 'name',
    },
    {
      text: 'Difficulty',
      sort: true,
      dataField: 'difficulty',
    },
    {
      text: 'Kategoriat',
      dataField: 'categories',
      formatter: (cell) => {
        const string = cell
          .map((cId: string) => categories.find((c) => c.id === cId))
          .filter((x: Category) => x)
          .map((c: Category) => c.name)
          .join(', ');
        return <>{string}</>;
      },
    },
    {
      text: 'Toiminnot',
      sort: true,
      dataField: 'id',
      formatter: (id) => {
        const quizUrl = `${URL.MODE.QUIZ.replace(':hour', 'hour')}?question=${id}`;
        const adminUrl = URL.QUESTION.EDIT.replace(':id', id);
        return (
          <>
            {isAdmin ? (
              <Button
                id={`edit.${id}`}
                className="btn-sm mr-2"
                onClick={() => dispatch(push(adminUrl))}
              >
                Muokkaa
              </Button>
            ) : (
              ''
            )}
            <Button id={`edit.${id}`} className="btn-sm" onClick={() => dispatch(push(quizUrl))}>
              Näytä
            </Button>
          </>
        );
      },
    },
  ];
  let createLink;
  if (isAdmin) createLink = URL.QUESTION.CREATE;
  return (
    <>
      <Card>
        <Formik onSubmit={onSubmit} initialValues={{ q: '' }}>
          <Form>
            <Input name="q" label="Haku" />
          </Form>
        </Formik>
      </Card>
      <GenericIndexScene
        key={JSON.stringify(where)}
        title="Kysymykset"
        reduxEntry={reduxEntry}
        listAction={listQuestionAction}
        dataTableProps={{ columns, query: { where } }}
        createLink={createLink}
      />
    </>
  );
};

export const QuestionEditScene = () => (
  <GenericFormScene
    title="Muokkaa kysymystä"
    reduxEntry="questionEntry"
    saveAction={updateQuestionAction}
    fetchAction={fetchQuestionAction}
    validationSchema={questionValidationSchema}
    delAction={deleteQuestionAction}
    initialValues={questionInitValues}
    EmbedForm={QuestionForm}
  />
);
