import { push } from 'react-router-redux';
import { actionCreator } from 'redux-rest-helper-for-loopback';
import ProgressSavesEffect from './ProgressSavesEffect';

export default class ProgressSavesAction {
  static ENTRY = 'PROGRESS_SAVE';

  static actionCreator = actionCreator(
    ProgressSavesAction.ENTRY,
    ProgressSavesEffect.restRequests,
    push
  );

  static create = ProgressSavesAction.actionCreator.getCreateAction();

  static update = ProgressSavesAction.actionCreator.getUpdateAction();

  static fetch = ProgressSavesAction.actionCreator.getFetchAction();

  static delete = ProgressSavesAction.actionCreator.getDeleteAction();

  static list = ProgressSavesAction.actionCreator.getListAndCountAction();

  static clean = ProgressSavesAction.actionCreator.getCleanAction();
}
