import { Field, FieldProps } from 'formik';
import React from 'react';

const Element = ({ form, field }: FieldProps) => {
  return (
    <textarea
      className="form-control"
      rows={10}
      onChange={(e) => form.setFieldValue(field.name, JSON.parse(e.target.value))}
      defaultValue={JSON.stringify(field.value)}
    />
  );
};

export default () => (
  <>
    <Field name="content" component={Element} />
  </>
);
