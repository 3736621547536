import { reducerCreator } from 'redux-rest-helper-for-loopback';
import ProgressSave from './ProgressSave';
import ProgressSavesAction from './ProgressSavesAction';

export default class ProgressSavesReducer {
  private static initValues: ProgressSave = {
    id: '',
    memberId: '',
    progressId: '',
    currentQuestion: '',
    questions: [],
    wrongAnswers: [],
    skipAnswers: [],
    correctAnswers: [],
    started: true,
    filter: {},
    isProgressionMode: true,
    score: 0,
    passScore: 0,
    currentStep: 0,
    scoreLimit: 0,
    categoryIds: [],
    lastStep: true,
  };

  public static reducer = reducerCreator(
    ProgressSavesAction.ENTRY,
    ProgressSavesReducer.initValues
  ).getReducer();
}
