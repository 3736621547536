import {
  actionCreator,
  requestCreator,
  reducerCreator,
  GenericInitState,
} from 'redux-rest-helper-for-loopback';
import { push } from 'react-router-redux';
import URL from '../../URL';
import { showError } from './notif.actions';
import { Progress } from '../../interfaces/Progress';
import { progressInitValues } from '../../forms/ProgressForm';

const entry = 'PROGRESS';

export const progressRequests = requestCreator<Progress>(URL.ProgressAPI);

const actions = actionCreator(entry, progressRequests, push, showError);

export const createProgressAction = actions.getCreateAction(URL.PROGRESS.INDEX);
export const updateProgressAction = actions.getUpdateAction(URL.PROGRESS.INDEX);
export const fetchProgressAction = actions.getFetchAction();
export const deleteProgressAction = actions.getDeleteAction(URL.PROGRESS.INDEX);
export const listProgressAction = actions.getListAndCountAction();
export const cleanProgressAction = actions.getCleanAction();

export const progressReducer = reducerCreator(entry, progressInitValues).getReducer();

export interface ProgressRedux extends GenericInitState<Progress> {}
