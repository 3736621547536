import DriveFile from './interfaces/DriveFile';
import URL from './URL';

export interface Config {
  sentry?: string;
  intercom?: string;
  ga?: string;
}

export default () => ({
  sentry: process.env.SENTRY_KEY || '',
  intercom: process.env.INTERCOM_KEY || '',
  ga: process.env.GA_KEY || '',
});

export const getFileUrl = (file: DriveFile) =>
  `${URL.ContainerAPI}/${file.bucket}/download/${file.fileName}`;
