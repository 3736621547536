import React from 'react';
import { Input } from 'releox-react';
import * as Yup from 'yup';
import { Category } from '../interfaces/Category';
import validationSchemaCreator from '../utils/validation-schema-creator';

export const categoryValidationSchema = validationSchemaCreator({
  name: Yup.string().required(),
});

export const categoryInitValues: Category = {
  id: '',
  name: '',
};

export default () => (
  <>
    <Input inline inlineLabelWidth={3} label="Nimi" name="name" />
  </>
);
