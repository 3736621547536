import { requestCreator } from 'redux-rest-helper-for-loopback';
import URL from '../../URL';
import ProgressSave from './ProgressSave';

export default class ProgressSavesEffect {
  public static restRequests = requestCreator<ProgressSave>(URL.ProgressSaveAPI);

  public static findById = ProgressSavesEffect.restRequests.getById;

  public static find = ProgressSavesEffect.restRequests.getAll;

  public static create = ProgressSavesEffect.restRequests.create;

  public static update = ProgressSavesEffect.restRequests.update;

  public static delete = ProgressSavesEffect.restRequests.delete;

  public static count = ProgressSavesEffect.restRequests.count;
}
