import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import { Button, Card } from 'releox-react';
import URL from '../../URL';

interface ModeCardProps {
  title: string;
  onClick(): void;
  onContinue?(): void;
}

export const ModeCard = ({ title, onClick, onContinue }: ModeCardProps) => {
  return (
    <div className="col-lg-3 col-md-6 text-center">
      <Card>
        <>
          <h3>{title}</h3>
          <Button className="m-2" id={`${title}-mode`} onClick={onClick}>
            Aloita
          </Button>
          {onContinue ? (
            <Button className="m-2" id={`${title}-mode`} onClick={onContinue}>
              Jatka
            </Button>
          ) : (
            ''
          )}
        </>
      </Card>
    </div>
  );
};

const ModeIndexScene = () => {
  const dispatch = useDispatch();

  const onClick = (url: string) => () => {
    dispatch(push(url));
  };

  return (
    <div className="row">
      <ModeCard title="Tuntimoodi" onClick={onClick(URL.MODE.SHOW.replace(':mode', 'hour'))} />
      <ModeCard
        title="Progressiomoodi"
        onClick={onClick(URL.MODE.SHOW.replace(':mode', 'progress'))}
      />
    </div>
  );
};
export default ModeIndexScene;
