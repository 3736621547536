import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import { Button, DataTableColumn, GenericIndexScene } from 'releox-react';
import { Answer } from '../interfaces/Answer';
import { listAnswerAction } from '../store/actions/answer.actions';
import { ReduxStore } from '../store/reducer';
import URL from '../URL';

const wrong = { isCorrect: false, isSkipped: false };
const correct = { isCorrect: true, isSkipped: false };
const skip = { isCorrect: false, isSkipped: true };

const classNames = 'm-2 btn btn-outline-primary';
const activeClassnames = 'm-2 btn btn-primary';

const compareClass = (a: any, b: any) => JSON.stringify(a) === JSON.stringify(b);
const getQuery = (a: any, b: any) => (compareClass(a, b) ? {} : a);
const getClass = (a: any, b: any) => (compareClass(a, b) ? activeClassnames : classNames);

export default () => {
  const dispatch = useDispatch();
  const memberId = useSelector((state: ReduxStore) => state.user.model.data.id);
  const [where, setWhere] = useState({});

  const columns: DataTableColumn[] = [
    {
      text: 'Kysymys',
      dataField: 'question.name',
    },
    {
      text: 'Vastaus',
      dataField: 'isCorrect',
      formatter: (b: boolean, answer: Answer) => {
        if (answer.isSkipped) return 'Ohitettu';
        if (answer.isCorrect) return 'Oikein';
        return 'Väärin';
      },
    },
    {
      text: 'Vastattu',
      dataField: 'createdAt',
      formatter: (createdAt: string) => moment(createdAt).format('D.M.YYYY HH:mm'),
    },
    {
      text: 'Vastattu',
      dataField: 'questionId',
      formatter: (questionId: string) => {
        const quizUrl = `${URL.MODE.QUIZ.replace(':hour', 'hour')}?question=${questionId}`;
        return (
          <Button
            id={`show.${questionId}`}
            className="btn-sm"
            onClick={() => dispatch(push(quizUrl))}
          >
            Näytä
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <button
        type="button"
        className={getClass(wrong, where)}
        onClick={() => setWhere(getQuery(wrong, where))}
      >
        Nayta vain vaarin vastatut
      </button>
      <button
        type="button"
        id="right"
        className={getClass(correct, where)}
        onClick={() => setWhere(getQuery(correct, where))}
      >
        Nayta vain oikein vastatut
      </button>
      <button
        type="button"
        id="skip"
        className={getClass(skip, where)}
        onClick={() => setWhere(getQuery(skip, where))}
      >
        Nayta vain ohitetut
      </button>
      <GenericIndexScene
        key={JSON.stringify(where)}
        title="Vastaukset"
        reduxEntry="answerEntry"
        listAction={listAnswerAction}
        dataTableProps={{
          columns,
          query: { include: ['question'], where: { memberId, ...where } },
        }}
      />
    </>
  );
};
