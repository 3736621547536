import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { authMiddleware, CoreuiLayout, Input, Loading, validateModel } from 'releox-react';
import { User } from './interfaces/User';
import AnswerIndex from './scenes/AnswerIndex';
import {
  CategoryCreateScene,
  CategoryEditScene,
  CategoryIndexScene,
} from './scenes/category.scenes';
import ContentScene from './scenes/ContentScene';
import { MemberCreateScene, MemberEditScene, MemberIndexScene } from './scenes/member.scenes';
import Profile from './scenes/Profile';
import {
  ProgressCreateScene,
  ProgressEditScene,
  ProgressIndexScene,
} from './scenes/progress.scenes';
import { QuestionEditScene, QuestionIndexScene } from './scenes/question.scenes';
import QuestionCreateScene from './scenes/QuestionCreate.scene';
import QuestionShow from './scenes/QuestionShow';
import Quiz from './scenes/Quiz';
import ModeIndexScene from './scenes/Student/ModeIndexScene';
import ProgressModeSelect from './scenes/Student/ProgressModeSelect';
import TableOfContentEditScene from './scenes/table-of-content/TableOfContentEditScene';
import TableOfContentIndex from './scenes/TableOfContentIndex';
import sidebarMenu from './sidebar.menu';
import { setUserAction } from './store/actions/user.actions';
import { ReduxStore } from './store/reducer';
import URL from './URL';

interface DashboardProps {
  authenticatedUser: User;
  user: User;
  setUser(user: User): void;
}

class DashboardRouter extends Component<DashboardProps> {
  componentDidMount() {
    const { setUser, authenticatedUser } = this.props;
    setUser(authenticatedUser);
  }

  render() {
    const { user } = this.props;
    if (!user.name) return <Loading centeredVertical />;
    return (
      <CoreuiLayout
        brand={<img src="/tkk.png" alt="logo" />}
        headerRightText={user.name}
        sidebarMenu={sidebarMenu(user)}
      >
        <Switch>
          <Route exact path={URL.USERS.INDEX} component={MemberIndexScene} />
          <Route exact path={URL.USERS.EDIT} component={MemberEditScene} />
          <Route exact path={URL.USERS.INVITE} component={MemberCreateScene} />
          <Route exact path={URL.MODE.HOUR_FILTER} component={ContentScene} />
          <Route exact path={URL.CATEGORY.INDEX} component={CategoryIndexScene} />
          <Route exact path={URL.CATEGORY.CREATE} component={CategoryCreateScene} />
          <Route exact path={URL.CATEGORY.EDIT} component={CategoryEditScene} />
          <Route exact path={URL.PROGRESS.INDEX} component={ProgressIndexScene} />
          <Route exact path={URL.PROGRESS.CREATE} component={ProgressCreateScene} />
          <Route exact path={URL.PROGRESS.EDIT} component={ProgressEditScene} />
          <Route exact path={URL.QUESTION.INDEX} component={QuestionIndexScene} />
          <Route exact path={URL.QUESTION.CREATE} component={QuestionCreateScene} />
          <Route exact path={URL.QUESTION.SHOW} component={QuestionShow} />
          <Route exact path={URL.QUESTION.EDIT} component={QuestionEditScene} />
          <Route exact path={URL.MODE.INDEX} component={ModeIndexScene} />
          <Route exact path={URL.MODE.PROGRESS_SELECT} component={ProgressModeSelect} />
          <Route exact path={URL.MODE.QUIZ} component={Quiz} />
          <Route exact path={URL.PROFILE} component={Profile} />
          <Route exact path={URL.ANSWER} component={AnswerIndex} />
          <Route exact path={URL.TABLE_OF_CONTENT.INDEX} component={TableOfContentIndex} />
          <Route exact path={URL.TABLE_OF_CONTENT.EDIT} component={TableOfContentEditScene} />
        </Switch>
      </CoreuiLayout>
    );
  }
}

const ValidationForm = () => <Input name="name" id="name" label="Nimi" />;

const mapStateToProps = (store: ReduxStore) => ({
  user: store.user.model.data,
});

const mapDispatchToProps = {
  setUser: setUserAction,
};

export const DashboardRouterComponent = DashboardRouter;
export const DashboardRouterValidationForm = ValidationForm;
export const dashboardRouterMapStateToProps = mapStateToProps;
export const dashboardRouterMapDispatchToProps = mapDispatchToProps;
export default authMiddleware(
  validateModel(
    ['name'],
    ValidationForm
  )(connect(mapStateToProps, mapDispatchToProps)(DashboardRouter))
);
