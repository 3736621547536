export default {
  fi: {
    TableOfContentEditScene: {
      title: 'Muokkaa sisällysluetteloa',
    },
  },
  en: {
    TableOfContentEditScene: {
      title: 'Edit Table of content',
    },
  },
};
