import { rednotReducer } from 'rednot';
import { combineReducers } from 'redux';
import { answerReducer } from './actions/answer.actions';
import { categoryReducer } from './actions/category.actions';
import { memberReducer } from './actions/member.actions';
import { progressReducer } from './actions/progress.actions';
import { questionReducer } from './actions/question.actions';
import { userReducer } from './actions/user.actions';
import ProgressSavesReducer from './progress-save/ProgressSavesReducer';
import quizReducer from './reducers/quiz.reducer';
import TableOfContentsReducer from './table-of-content/TableOfContentsReducer';

const reducer = combineReducers({
  user: userReducer,
  rednot: rednotReducer,
  memberEntry: memberReducer,
  categoryEntry: categoryReducer,
  questionEntry: questionReducer,
  quizEntry: quizReducer,
  progressEntry: progressReducer,
  answerEntry: answerReducer,
  tableOfContent: TableOfContentsReducer,
  progressSave: ProgressSavesReducer.reducer,
});

export default reducer;

export type ReduxStore = ReturnType<typeof reducer>;
