import * as Sentry from '@sentry/browser';
import { push } from 'react-router-redux';
import {
  GenericInitState,
  actionCreator,
  requestCreator,
  reducerCreator,
  createReducer,
  createInitState,
} from 'redux-rest-helper-for-loopback';
import { User } from '../../interfaces/User';
import { showError } from './notif.actions';
import URL from '../../URL';

export const userFormInitValues: User = {
  id: '',
  name: '',
  email: '',
  isAdmin: false,
};

const name = 'USER';
const apiURL = URL.MemberAPI;

export const SET_USER = `@${name}:SET_USER`;

const rc = requestCreator<User>(apiURL);
const ac = actionCreator<User>(name, rc, push, showError);

const userHandlers = reducerCreator(name, userFormInitValues).getHandlers();

const setUser = (_: GenericInitState<User>, action: any, initState: GenericInitState<User>) => ({
  ...initState,
  model: { data: action.payload, isLoading: false },
});

export const userReducer = createReducer(
  {
    ...userHandlers,
    [SET_USER]: setUser,
    '@USER:FETCH_REQUEST': undefined,
    '@USER:FETCH_SUCCESS': undefined,
  },
  createInitState(userFormInitValues)
);

export const updateUserAction = ac.getUpdateAction();
export const fetchUserAction = ac.getFetchAction();

export const setUserToSentry = (user: User) => (scope: Sentry.Scope) => scope.setUser(user as any);

export const setUserAction = (user: User) => {
  Sentry.configureScope(setUserToSentry(user));
  return {
    type: SET_USER,
    payload: user,
  };
};

export interface UserRedux extends GenericInitState<User> {}
