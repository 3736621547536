import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ThunkDispatch } from 'redux-thunk';
import moment from 'moment';
import { connect } from 'react-redux';
import { FieldProps } from 'formik';
import Axios, { AxiosResponse, AxiosError } from 'axios';
import { User } from '../interfaces/User';
import DriveFile, { DriveFileBody } from '../interfaces/DriveFile';
import { showErrorMessage } from '../store/actions/notif.actions';
import { ReduxStore } from '../store/reducer';

interface Props extends FieldProps<any> {
  dispatch: ThunkDispatch<any, any, any>;
  user: User;
  name: string;
  label: string;
  bucket: string;
  fileName?: string;
}

interface AWSFile {
  container: string;
  field: string;
  name: string;
  providerResponse: any;
  size: number;
  type: string;
}

interface AWSFiles {
  files: AWSFile[];
}

interface AWSResult {
  files: AWSFiles;
}

interface AWSResponse {
  result: AWSResult;
}

const filePusher = (data: AWSResponse, user: User, name: string) => {
  const driveFiles: DriveFileBody[] = [];
  const awsResult = data as AWSResponse;
  const awsFiles = awsResult.result.files.files;
  awsFiles.forEach((awsFile) => {
    return driveFiles.push({
      name: awsFile.name.split('-')[1],
      fileName: awsFile.name,
      bucket: awsFile.container,
      type: awsFile.type,
      location: awsFile.providerResponse.location,
      size: awsFile.size,
      memberId: user.id,
      fileType: name,
    });
  });
  return driveFiles;
};

const FileInput = (props: Props) => {
  const { label, form, field } = props;
  const [statedFile, setStatedFile] = useState<DriveFile>({} as any);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles: any[]) => {
      const { dispatch, user, bucket } = props;
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const formData = new FormData();

      acceptedFiles.forEach((file) => {
        formData.append('files', file, `${moment().unix()}-${file.name}`);
      });

      Axios.post(`${window.API_ENDPOINT}/Containers/${bucket}/upload`, formData, config)
        .then((r: AxiosResponse) =>
          Axios.post(`${window.API_ENDPOINT}/DriveFiles`, filePusher(r.data, user, field.name))
        )
        .then((r: AxiosResponse) => {
          form.setFieldValue(field.name, r.data[0].id);
          setStatedFile(r.data[0]);
        })
        .catch((e: AxiosError) => dispatch(showErrorMessage(e.name)));
    },
  });

  useEffect(() => {
    if (field.value)
      Axios.get(`${window.API_ENDPOINT}/DriveFiles/${field.value}`).then((r) =>
        setStatedFile(r.data)
      );
  }, [field.value]);

  return (
    <div className="form-group row FileInput">
      <label htmlFor={field.name} className="col-md-3 col-form-label">
        {label}
      </label>
      <div className="col-md-9">
        <div>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <div className="btn btn-primary ml-3" {...getRootProps()}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <input name={field.name} id={field.name} {...getInputProps()} />
            Valitse tiedosto
          </div>
        </div>
        <div>
          <p className="ml-3">{statedFile.name}</p>
          {statedFile.id ? (
            <img
              src={`${window.API_ENDPOINT}/Containers/${statedFile.bucket}/download/${statedFile.fileName}`}
              alt=""
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store: ReduxStore) => ({
  user: store.user.model.data,
});

export default connect(mapStateToProps)(FileInput);
