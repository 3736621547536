import React from 'react';
import { GenericIndexScene } from 'releox-react';
import TableOfContentsAction from '../store/table-of-content/TableOfContentsAction';
import URL from '../URL';

export default () => {
  return (
    <GenericIndexScene
      title="Vastaukset"
      reduxEntry="tableOfContent"
      listAction={TableOfContentsAction.list}
      redirectUrl={URL.TABLE_OF_CONTENT.EDIT}
      dataTableProps={{
        columns: [{ dataField: 'id', text: 'ID' }],
      }}
    />
  );
};
